export const TEXT_OF = {
  ICON_ACTION: "icon_action",
};

export const DEFAULT_HEADER_FOOTER = {
  HEADER_FILTER: { code: 'header', status: 'active' },
  FOOTER_FILTER: { code: 'footer', status: 'active' },
};

export const DATE_FORMAT = 'DD/MM/yyyy HH:mm';
export const DEFAULT_TENANT_TIMEZONE = 'Europe/Amsterdam'

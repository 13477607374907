import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';
import { DATE_FORMAT, DEFAULT_TENANT_TIMEZONE } from '../shared/utils/const.util';

@Pipe({
  name: 'convertTimezone'
})
export class ConvertTimezonePipe implements PipeTransform {

  transform(value: any): any {
    let timezone = localStorage.getItem('timezone') || DEFAULT_TENANT_TIMEZONE;
    
    return moment(value).tz(timezone).format(DATE_FORMAT);
  }
}
